<template>
  <div class="log_item">
    <div
      :style="{
        'background-color': actions[log.action.value].color
      }"
      class="log_item-icon"
    >
      <i :class="`bi bi-${actions[log.action.value].icon}`" />
    </div>
    <img
      v-if="log.user"
      :src="avatarUrl"
      class="log_item-avatar"
    >
    <div
      v-else
      class="log_item-avatar"
    />
    <div class="log_item-content">
      <div class="log_item-content-description">
        {{ log.label }}
      </div>
      <div class="log_item-content-signature">
        <span
          v-if="log.user"
          class="log_item-content-signature-user"
        >
          Par 
          <a
            @click="$router.push({
              name: 'UserDetail',
              params: {
                id: log.user.id
              }
            })"
          >
            {{ log.user.label }}
          </a>
          ,
        </span>
        <span
          v-if="$i18n.locale === 'en'"
          class="log_item-content-signature-date"
          @mouseenter="isDateTooltipVisible = true"
          @mouseleave="isDateTooltipVisible = false"
        >
          {{ timeSinceEn(new Date(log.action_time)) }} ago
        </span>
        <span
          v-else
          class="log_item-content-signature-date"
          @mouseenter="isDateTooltipVisible = true"
          @mouseleave="isDateTooltipVisible = false"
        >
          il y a {{ timeSinceFr(new Date(log.action_time)) }}
        </span>
        <div
          :class="{ visible: isDateTooltipVisible }"
          class="date_tooltip"
        >
          {{ new Date(log.action_time).toLocaleDateString(
            $i18n.locale,
            {
              weekday: 'long',
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            })
          }}  
          {{ new Date(log.action_time).toLocaleTimeString() }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { timeSinceEn, timeSinceFr } from '@/utils/date.js';

export default {
  name: 'LogItem',

  props: {
    log: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      actions: {
        1: {
          icon: 'file-plus',
          color: '#8ecb81'
        },
        2: {
          icon: 'file-earmark-diff',
          color: '#cb81c9'
        },
        3: {
          icon : 'file-minus',
          color: '#cb8181'
        }
      },
      isDateTooltipVisible: false
    };
  },

  computed: {
    avatarUrl() {
      if (this.log.user && this.log.user.id) {
        return `https://robohash.org/${this.log.user.id}`;
      }
      return '';
    }
  },

  methods: {
    timeSinceEn,
    timeSinceFr
  }
};
</script>

<style scoped lang="less">
.log_item {
  display: flex;
  margin: 1rem 0;
  width: fit-content;
  position: relative;
  .log_item-icon {
    height: 2rem;
    width: 2rem;
    min-width: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-right: 1rem;
    i {
      color: #FFFFFF;
      font-weight: 600;
    }
  }
  .log_item-avatar {
    height: 2rem;
    width: 2rem;
    min-width: 2rem;
    border-radius: 50%;
    margin-right: 1rem;
  }
  .log_item-content {
    display: flex;
    flex-direction: column;
    padding: 0.25rem 0.5rem;
    background-color: #f6faff;
    border-radius: 2px;
    box-shadow: 0px 1px 4px rgba(14, 31, 53, 0.12), 0px 4px 8px rgba(14, 31, 53, 0.08);
    .log_item-content-description {
      color: #424C55;
      font-size: 0.9rem;
      font-weight: 500;
      margin-right: 0.5rem;
    }
    .log_item-content-signature {
      font-size: 0.7rem;
      font-weight: 600;
      position: relative;
      .log_item-content-signature-user {
        color: #686868;
        a {
          color: #187CC6;
          cursor: pointer;
        }
      }
      .log_item-content-signature-date {
        cursor: default;
        color: #939393;
      }
      .date_tooltip {
        display: none;
        position: absolute;
        left: 5rem;
        top: -1.5rem;
        background-color: #fdffee;
        padding: 4px 8px;
      }
      .date_tooltip.visible {
        display: block;
      }
    }
  }
}
.log_item:not(:last-child) {
  .log_item-icon:after {
    content:"";
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: -100%;
    left: 1rem;
    border-left: 2px dotted #424C55;
    transform: translate(-50%);
  }
}
</style>