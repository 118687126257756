import { render, staticRenderFns } from "./LogsView.vue?vue&type=template&id=1fd31870&scoped=true"
import script from "./LogsView.vue?vue&type=script&lang=js"
export * from "./LogsView.vue?vue&type=script&lang=js"
import style0 from "./LogsView.vue?vue&type=style&index=0&id=1fd31870&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1fd31870",
  null
  
)

export default component.exports